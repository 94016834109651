import { ref } from 'vue';
import { projectAuth } from '../firebase/config';

// refs
const user = ref(projectAuth.currentUser);
const error = ref(null);

// update user's profile name
const updateProfileName = async (displayName) => {
    error.value = null;
    try {
        await user.value.updateProfile({
            displayName: displayName
        });
    } catch (err) {
        console.log(err.message);
        error.value = err.message;
    }
};

// update user's profile email
const updateProfileEmail = async (email) => {
    error.value = null;
    try {
        await user.value.updateEmail(email);
    } catch (err) {
        console.log(err.message);
        error.value = err.message;
    }
};

const useUser = () => {
    return { error, updateProfileName, updateProfileEmail };
};

export default useUser;